import React from "react";
import { InsiderIconWithGreyBg } from "../../components/ui/icons/InsiderIconWithGreyBg";
import { Music } from "../../components/ui/icons/CategoryIcons/Music";
import { Festivals } from "../../components/ui/icons/CategoryIcons/Festival";
import { Theatre } from "../../components/ui/icons/CategoryIcons/Theatre";
/**
 * @type {Link}
 * @property {string} resource_type - The type of resource, possible values are: "tag", "event", "article", "category", "external", "group", "go" and "filtered_list"
 * @property {string} [title] - The title of the link.
 * @property {string} [slug] - The slug for the link.
 * @property {string} [url] - The URL of the link (applicable for "external" resource_type).
 * @property {Object} [query_params] - Additional query parameters for the link (applicable for "filtered_list" resource_type).
 */
/**
 * @type {IIconCard}
 * @property {string} title - The title of the quick link.
 * @property {string} subtitle - The subtitle associated with the quick link.
 * @property {string} image - The URL of the image associated with the quick link.
 * @property {string[]} [includeCities] - An array of cities to include for the quick link. Add ["global"] for adding it in all cities. includeCities takes priority over excludeCities
 * @property {string[]} [excludeCities] - An array of cities to exclude for the quick link.
 * @property {Link} link - The object containing params to construct the links
 * @property {boolean} openInNewTab - Boolean set to true to open links in new tab
 */
/**
 * Configuration for the homepage UI components.
 * @type {IIconCard[]}
 */
// this is a temporary config to power the homepage ui components untill the admin flow is built
// note: if more than 3 quicklinks qualify for a city, they will be shuffled
export const quickLinks = [
    //Mumbai
    {
        title: "Wine & Dine",
        subtitle: "With Bandra WineOut",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739085767/wif1xvlqpuzhzmayizum.png",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "the-bandra-wineout-2025-feb15-2025"
        }
    },
    {
        title: "IIFA 2024 Night",
        subtitle: "Bollywood’s Grand Stage",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739085861/qr6d5rrnkv1ivckhhopr.png",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "sobha-realty-iifa-awards-jaipur-mar8-2025"
        }
    },
    {
        title: "Flavours & Fun",
        subtitle: "Only at Zomaland",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738344052/wzfrfcdo3b4stharxhyf.png",
        includeCities: ["Mumbai"],
        link: {
            resource_type: "event",
            slug: "rupay-zomaland-by-zomato-live-mumbai-feb15-16-2025"
        }
    },
    //Delhi
    {
        title: "9 Chefs, 9 Artists",
        subtitle: "Unique Dining Exp.",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086004/gdivxwvfjevxuikqqmdc.png",
        includeCities: ["Delhi"],
        link: {
            resource_type: "go",
            slug: "the-gathering"
        }
    },
    {
        title: "Mughal E Azam",
        subtitle: "The Musical!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735626793/jqdj9xq8ibw813xarca0.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "mughal-e-azam-delhi-feb13-2025"
        }
    },
    {
        title: "It's an Uproar",
        subtitle: "A Simba Uproar",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735627316/omgwg0jmw0bbirepvt81.jpg",
        includeCities: ["Delhi"],
        link: {
            resource_type: "event",
            slug: "simba-uproar-2025-delhi-2025"
        }
    },
    //Bengaluru
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738342501/mt1xoyq6yupkthmrwboj.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-bangalore-mar22-2025"
        }
    },
    {
        title: "Rock with DSP",
        subtitle: "Holi with a Rockstar",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1727699645/pu3orpl4mqcggj4dlecj.jpg",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-bengaluru-mar15-2025"
        }
    },
    {
        title: "Flavours & Fun",
        subtitle: "Only at Zomaland",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738344052/wzfrfcdo3b4stharxhyf.png",
        includeCities: ["Bengaluru"],
        link: {
            resource_type: "event",
            slug: "rupay-zomaland-by-zomato-live-bengaluru-mar15-16-2025"
        }
    },
    //Pune
    {
        title: "Comic Con Pune",
        subtitle: "March 8th 2025",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734791162/nbikqz3iwvysciecrqso.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "pune-comic-con-2025-mar8-2025"
        }
    },
    {
        title: "RITVIZ x Holi",
        subtitle: "Barso Holi Carnival",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086188/tztde05ooign57nuufuv.png",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "barso-holi-carnival-ft-ritviz-mar14-2025"
        }
    },
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270411/ixidrpmrtsppinf9b5qk.jpg",
        includeCities: ["Pune"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-pune-mar14-2025"
        }
    },
    //Goa
    {
        title: "Handpan Music",
        subtitle: "With Vivaan, Feb 14",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737895050/ny6plpbb5f7i2m2ofxrv.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "vivaan-handpan-world-music-festival-feb14-2025"
        }
    },
    {
        title: "Laughs in Goa",
        subtitle: "With Jamie Lever",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738342655/uw3pfz7y48mr4sp9nolk.jpg",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "the-jamie-lever-show-feb14-2025"
        }
    },
    {
        title: "RITVIZ x Holi",
        subtitle: "Barso Holi Carnival",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086188/tztde05ooign57nuufuv.png",
        includeCities: ["Goa"],
        link: {
            resource_type: "event",
            slug: "barso-holi-carnival-ft-ritviz-mar14-2025"
        }
    },
    //Hyderabad
    {
        title: "Wonderla Thrills",
        subtitle: "Ultimate Fun & Rides",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086268/zqruvqkxil0gxwrfrhpn.png",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "wonderla-amusement-park-hyderabad"
        }
    },
    {
        title: "Oscar Winner",
        subtitle: "M.M. Keeravani Live!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738342709/hvcb1pxdeqejwknfcvef.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "mm-keeravani-live-in-concert-mar22-2025"
        }
    },
    {
        title: "Sid Sriram",
        subtitle: "LIVE in Hyderabad!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1733509654/g2mieghbtpvyxioij7bq.jpg",
        includeCities: ["Hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    //Chennai
    {
        title: "Feel the Magic!",
        subtitle: "Groove with Harris!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086309/u8gbg6t04evbxzh7gaap.png",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "rock-on-harris-cbe-edition-mar15-2025"
        }
    },
    {
        title: "All Hearts Tour",
        subtitle: "Shreya Ghoshal LIVE",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1732292802/y8leuuxuykqhmzxu8hcs.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "shreya-ghoshal-live-all-hearts-tour-chennai-mar1-2025"
        }
    },
    {
        title: "Deva LIVE!",
        subtitle: "In Chennai on Feb 15",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735635949/rm0r3ecttiqo354yjuwc.jpg",
        includeCities: ["Chennai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-feb15-2025"
        }
    },
    //Kolkata
    {
        title: "Flavours & Fun",
        subtitle: "Only at Zomaland",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738344052/wzfrfcdo3b4stharxhyf.png",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "rupay-zomaland-by-zomato-live-kolkata-mar1-2-2025"
        }
    },
    {
        title: "Comic Con '25",
        subtitle: "In Kolkata this Feb!",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1735627411/bkyjkpmtktsau6hv5dpl.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "kolkata-comic-con-2025-feb22-2025"
        }
    },
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh ",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737894059/a5kakqhwgcywwn23rhte.jpg",
        includeCities: ["Kolkata"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-kolkata-april5-2025"
        }
    },
    //Chandigarh
    //Jaipur
    {
        title: "IIFA 2024 Night",
        subtitle: "Bollywood’s Grand Stage",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739085861/qr6d5rrnkv1ivckhhopr.png",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "sobha-realty-iifa-awards-jaipur-mar8-2025"
        }
    },
    {
        title: "Flavours & Fun",
        subtitle: "Only at Zomaland",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738344052/wzfrfcdo3b4stharxhyf.png",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "rupay-zomaland-by-zomato-live-jaipur-feb22-23-2025"
        }
    },
    {
        title: "Tulum Vibes",
        subtitle: "At ZAMNA India",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1739086468/c8owkrdtpobz2qsbvyka.png",
        includeCities: ["Jaipur"],
        link: {
            resource_type: "event",
            slug: "zamna-india--gurugram"
        }
    },
    //Indore
    {
        title: "Comic Con 2025",
        subtitle: "In Ahmedabad, Mar 22",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1734791162/nbikqz3iwvysciecrqso.jpg",
        includeCities: ["Indore"],
        link: {
            resource_type: "event",
            slug: "ahmedabad-comic-con-2025-mar22-2025"
        }
    },
    {
        title: "Flavours & Fun",
        subtitle: "Only at Zomaland",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1738344052/wzfrfcdo3b4stharxhyf.png",
        includeCities: ["Indore"],
        link: {
            resource_type: "event",
            slug: "rupay-zomaland-by-zomato-live-indore-mar22-23-2025"
        }
    },
    {
        title: "OG Rap King!",
        subtitle: "Yo Yo Honey Singh",
        image: "https://media.insider.in/image/upload/c_crop,g_custom/v1737270411/ixidrpmrtsppinf9b5qk.jpg",
        includeCities: ["Indore"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-indore-mar8-2025"
        }
    },
    //Mohali
];
export const placeholderQuickLinks = [
    {
        title: "Host an Event",
        subtitle: "Make your event live in under 5 mins",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "/list-your-events"
        }
    },
    {
        title: "Events this weekend",
        subtitle: "Events this weekend",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "everything",
            time_filter: "weekend"
        }
    }
];
export const mobilePlaceHolderLinks = [
    {
        title: "Download our app now!",
        subtitle: "Android & iOS",
        image: <InsiderIconWithGreyBg />,
        includeCities: ["global"],
        link: {
            resource_type: "external",
            url: "https://link.insider.in/app"
        }
    },
    ...placeholderQuickLinks
];
/**
     Key to be added for iOs on categoryBanners array:
     * hideForIOS : true if we don't want to show banner on iOs devices
     * showForIOS : true if we want to to show a different banenr for iOs device, create another object named categoryBannersForIOs with same format
     * * don't make both true
     * * No need to add above keys on categoryBannersForIOs, adding keys (if required) on original config array is sufficient.
     * * Don't add any key if not required
     */
export const categoryBannersForIOs = [];
export const categoryBanners = [
    //Category Banner
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664679/kyacretfotqazjukyxtq.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736664679/ps3aehvp2l86utc2gvop.jpg",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-mumbai-feb22-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738343019/tlh2l67uxtv6yyjptryj.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738342881/u4m4sjfdgjtbh5ix3ti7.jpg",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "zamna-india--gurugram"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685653/xmdzggafyeuhivwvuej2.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685641/otenmvikg11utepdoj2q.jpg",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-bangalore-mar22-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685703/dqajugmpnytfg0iyg39x.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685705/qqxrjaqnfm4bpxd6zllf.jpg",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-pune-mar14-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738563473/qiv99iq6gffv2mckhszi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738563578/fmsta42qli6ikrovlv76.jpg",
        includeCities: ["goa"],
        link: {
            resource_type: "event",
            slug: "vivaan-handpan-world-music-festival-feb14-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738343203/pa42gcv5dt95fzoub5e5.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1738343120/maw5exbjd4pbtc697a5x.jpg",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685927/mofem9wnk7ag7fpjvzxt.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685927/sqimn5fwqyuwzimhggnz.jpg",
        includeCities: ["kolkata"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-kolkata-april5-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685986/fsunvqbnsst1vrfcxvqi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685986/hhvzki69nbnohxu97aqa.jpg",
        includeCities: ["chandigarh"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-chandigarh-mar23-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686051/eafhnybs97mo2rmfehab.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686050/bbvsyw141zrl7vbhtano.jpg",
        includeCities: ["jaipur"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-jaipur-march29-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686092/mrhkpmatpvbruibkbbv1.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686092/setlefiulqzgzr2t0bry.jpg",
        includeCities: ["indore"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-indore-mar8-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686129/atdil3on8fwj2rzcxo2y.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736686129/zayfcqnw4nqwu7ic8yda.jpg",
        includeCities: ["lucknow"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-lucknow-feb28-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737895813/qvhn9jbrukdh5frwlw47.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737895810/wrvvt9jvkgelwna4gq1j.jpg",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-feb15-2025"
        }
    },
    {
        categoryId: "57d6897a272b564fc04b24ac",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685986/fsunvqbnsst1vrfcxvqi.jpg",
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736685986/hhvzki69nbnohxu97aqa.jpg",
        includeCities: ["mohali"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-chandigarh-mar23-2025"
        }
    },
];
export const showcase = [
    //Type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1731996140/e1ngi3bmybm8d7at9ys8.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1732175414/lu3sd63xqnv0xcs0gy1d.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh - LIVE in Concert",
        subheading: "The Voice of the generation makes a much awaited comeback to the stage.",
        caption: 'After two sold out cities, Arijit Singh’s India tour comes to yours! Get ready for an unforgettable musical journey with India’s most beloved voice.',
        cta: "Buy Now",
        includeCities: ["mumbai"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-india-tour-mumbai-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628286/hahtp6za8vrbwkspot9u.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628323/jfc3tlf3xlljo8nhxczd.jpg",
        categoryIcon: <Theatre />,
        heading: "Mughal-e-Azam: Live!",
        subheading: "India’s Biggest Broadway-Style Musical",
        caption: 'Experience the grandeur of Mughal-e-Azam! With 150+ cast members, stunning Kathak, and award-winning design, this timeless musical brings Indian heritage to life.',
        cta: "Buy Now",
        includeCities: ["delhi"],
        link: {
            resource_type: "event",
            slug: "mughal-e-azam-delhi-feb13-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271780/yiljlkdwj7kfir1lmcjv.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737271781/tlpa1m91vgnlp2rgiok6.jpg",
        categoryIcon: <Music />,
        heading: "Rockstar DSP Live!",
        subheading: "High energy hits await you!",
        caption: 'An excellent dancer. A versatile composer. A chart-topper. DSP infuses his songs with irresistible dance spirits! Get ready to feel the vibes LIVE.',
        cta: "Buy Now",
        includeCities: ["bengaluru"],
        link: {
            resource_type: "event",
            slug: "rockstar-dsp-live-in-bengaluru-mar15-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/wx64dwheikrd4pphafj5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/sbsum04y34h42ger3i4z.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh Live Tour",
        subheading: "Experience India's most beloved voice live!",
        caption: 'Get ready to witness Arijit Singh perform timeless hits like "Tum Hi Ho," "Kesariya," and "Channa Mereya" in an unforgettable live experience!',
        cta: "Know More",
        includeCities: ["pune"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-live-in-pune-2025-mar16-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628745/fskx2zco0axygh2ithau.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1735628832/caxuwsnfehwhnuovrht0.jpg",
        categoryIcon: <Music />,
        heading: "A Soulful Journey!",
        subheading: "Join Sid Sriram in Hyderabad on Feb 15th for an unforgettable evening!",
        caption: "Sid Sriram's Chennai-meets-global sound comes to Hyderabad, Feb 15th!",
        cta: "Buy Now",
        includeCities: ["hyderabad"],
        link: {
            resource_type: "event",
            slug: "sid-sriram-live-in-concert-hyderabad-feb15-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734119476/bv1avix9hfwctafsguey.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734119479/ei4jkvhpp6wc5ev2kajz.jpg",
        categoryIcon: <Music />,
        heading: "Deva Live in Chennai!",
        subheading: "Join us on Feb 15th to celebrate the legend Deva with his timeless hits!",
        caption: "Madurai, get ready to vibe with Deva! Enjoy his all-time hits from Baasha, Arunachalam, and more. Celebrate the musical legend in Chennai!",
        cta: "Buy Now",
        includeCities: ["chennai"],
        link: {
            resource_type: "event",
            slug: "thenisai-thendral-deva-live-in-concert-feb15-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    // Type C
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703987/gfac98re6ce5jxypfwop.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1728703982/mcu7jtu4cewadeo0mvsd.jpg",
        categoryIcon: <Festivals />,
        heading: "Comic Con 2025",
        subheading: "The greatest pop-culture extravaganza",
        caption: "An epic showcase of comics, cosplay, collectible merch, toys, immersive fan experiences, gaming, and more await. Get Your Tickets!",
        cta: "Book Now",
        includeCities: ["kolkata"],
        cardListType: "small-event-card",
        tag: "comic-con-2024",
        cardListLabel: "GRAB your tickets now!",
        link: {
            resource_type: "go",
            slug: "comic-con-2024"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: false
    },
    // Type A
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/wx64dwheikrd4pphafj5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/sbsum04y34h42ger3i4z.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh Live Tour",
        subheading: "Experience India's most beloved voice live!",
        caption: 'Get ready to witness Arijit Singh perform timeless hits like "Tum Hi Ho," "Kesariya," and "Channa Mereya" in an unforgettable live experience!',
        cta: "Buy Now",
        includeCities: ["chandigarh"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-live-in-concert-chandigarh-feb16-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737894708/xapzfiv93fbcuov1qala.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1737894829/dej4vgagxghhdo5uly8o.jpg",
        categoryIcon: <Music />,
        heading: "Yo Yo Honey Singh LIVE",
        subheading: "The OG Hitmaker’s back on tour!",
        caption: 'Get ready for a night of legendary hits as Yo Yo Honey Singh sets the stage on fire! From Brown Rang to Love Dose, vibe to the bangers that redefined Indian music.',
        cta: "Buy Now",
        includeCities: ["jaipur"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-jaipur-march29-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/a2f302q5jdphvsbgktsi.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/td7dl6kycur5at31bt1z.jpg",
        categoryIcon: <Music />,
        heading: "Yo Yo Live in Indore",
        subheading: "Bawaal Pakka Hai! Yo Yo Honey Singh is all set to take over your city!",
        caption: "If you’re not vibing with Yo Yo Honey Singh LIVE, wyd? Indore, lock in your plans, get your crew, and let’s TURN IT UP!",
        cta: "Buy Now",
        includeCities: ["indore"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-indore-mar8-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/a2f302q5jdphvsbgktsi.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1736687392/td7dl6kycur5at31bt1z.jpg",
        categoryIcon: <Music />,
        heading: "Millionaire Tour Live",
        subheading: "The OG Desi Kalakar is coming with his biggest-ever tour!",
        caption: "Lucknow, ho jao ready for a night of non-stop beats & swag! Honey Singh is bringing his all-time greatest hits LIVE! Grab your squad & book your tickets!",
        cta: "Buy Now",
        includeCities: ["lucknow"],
        link: {
            resource_type: "event",
            slug: "millionaire-india-tour-yo-yo-honey-singh-live-lucknow-feb28-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    },
    {
        mobileImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/wx64dwheikrd4pphafj5.jpg",
        desktopImage: "https://media.insider.in/image/upload/c_crop,g_custom/v1734600935/sbsum04y34h42ger3i4z.jpg",
        categoryIcon: <Music />,
        heading: "Arijit Singh Live Tour",
        subheading: "Experience India's most beloved voice live!",
        caption: 'Get ready to witness Arijit Singh perform timeless hits like "Tum Hi Ho," "Kesariya," and "Channa Mereya" in an unforgettable live experience!',
        cta: "Buy Now",
        includeCities: ["mohali"],
        link: {
            resource_type: "event",
            slug: "arijit-singh-live-in-concert-chandigarh-feb16-2025"
        },
        overrideShowInCurrentCityCheck: true,
        openExternalLinkInNewTab: true
    }
];
